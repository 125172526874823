import { render, staticRenderFns } from "./Splash.vue?vue&type=template&id=1e66914a&scoped=true&lang=pug&"
import script from "./Splash.vue?vue&type=script&lang=js&"
export * from "./Splash.vue?vue&type=script&lang=js&"
import style0 from "./Splash.vue?vue&type=style&index=0&id=1e66914a&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1e66914a",
  null
  
)

export default component.exports